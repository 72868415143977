<template lang="pug">
inventoryMap
</template>

<script>
import inventoryMap from '@/components/inventory/map';

export default {
	name: 'Create',
	components: {
		inventoryMap,
	},
};
</script>

<style scoped></style>
